<template>
    <div class='advertisingManagement'>
        <div class="planlist" v-show="!handlePlanPage">
            <el-row style="margin-bottom:1rem">
                <el-col :span="12">
                    <el-button type="primary" @click="handlePlanItem('create')">
                        <i class="el-icon-plus"></i>
                        新建广告
                    </el-button>
                </el-col>
                <el-col :span="12" style="text-align:right">
                    <el-select v-model="paginationInfo.group" placeholder="请选择广告组" filterable clearable>
                        <el-option v-for=" item in groupData " :key="item.name" :label="item.name" :value="item.name">
                        </el-option>
                    </el-select>
                    <el-button @click="queryList">查询</el-button>
                </el-col>
            </el-row>
            <el-table :data="tableData" style="width: 100%">
                <el-table-column prop="group" label="广告组" align='center'>
                </el-table-column>
                <el-table-column prop="name" label="广告名称" align='center'>
                </el-table-column>
                <el-table-column prop="budget" label="预算（元）" align='center'>
                </el-table-column>
                <el-table-column prop="price" label="单价（元）" align='center'>
                </el-table-column>
                <el-table-column label="投放开始时间" align='center'>
                    <template slot-scope="scope">
                        {{ ($moment(scope.row.start_time).format(`YYYY-MM-DD HH:mm:ss`)) }}
                    </template>
                </el-table-column>
                <el-table-column label="投放结束时间" align='center'>
                    <template slot-scope="scope">
                        {{ ($moment(scope.row.end_time).format(`YYYY-MM-DD HH:mm:ss`)) }}
                    </template>
                </el-table-column>
                <el-table-column label="广告素材" align='center' width="300">
                    <template slot-scope="scope">
                        <img style="width:100%" :src="scope.row.material_url" alt="">
                    </template>
                </el-table-column>
                <!-- <el-table-column prop="date" label="展示次数" align='center'>
                </el-table-column>
                <el-table-column prop="date" label="点击次数" align='center'>
                </el-table-column> -->
                <el-table-column prop="date" label="展示条件" align='center'>
                </el-table-column>
                <el-table-column prop="landing_page" label="推广链接" align='center'>
                </el-table-column>
                <el-table-column prop="date" label="操作" align='center'>
                    <template slot-scope="scope">
                        <el-button type="primary" size="mini" @click="handlePlanItem('edit', scope.row)">编辑</el-button>
                        <el-button v-if="scope.row.status == 1" type="warning" size="mini"
                            @click="handleOnAndOff(0, scope.row)">关闭</el-button>
                        <el-button v-else-if="scope.row.status == 0" type="success" size="mini"
                            @click="handleOnAndOff(1, scope.row)">开启</el-button>
                        <el-button type="danger" size="mini" @click="handlePlanItem('delete', scope.row)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                :current-page="paginationInfo.pageNo" :page-sizes="[10, 20, 50, 100]"
                :page-size="paginationInfo.pageSize" :total="paginationInfo.total"
                layout="total, sizes, prev, pager, next, jumper">
            </el-pagination>
        </div>
        <div class="formWrite" v-show="handlePlanPage">
            <h2>
                <span v-show="formType == 1">新建广告</span>
                <span v-show="formType == 2">编辑广告</span>
            </h2>
            <div class="formwrap">
                <h3>
                    <i class="el-icon-edit"></i>
                    <span>广告信息</span>
                </h3>
                <el-form :model="formData" :rules="rules" ref="formData" label-width="100px" class="demo-formData">
                    <el-form-item label="广告名称" prop="name">
                        <el-input v-model="formData.name"></el-input>
                    </el-form-item>
                    <el-form-item label="广告素材" prop="material_url">
                        <div>
                            <el-image style="width:300px" v-if="formData.material_url" :src="formData.material_url"
                                fit="fill" />
                        </div>
                        <div>
                            <el-upload style="display:inline-block" :action="uploadUrl" :headers="uploadHeader"
                                :show-file-list="false" accept="image/png, image/jpeg, image/gif, image/webp"
                                :on-success="coverUploadSuccess" :on-error="coverUploadError"
                                :before-upload="coverBeforeUpload">
                                <el-button type="primary" class="bCbN" v-if="!formData.material_url">添加图片</el-button>
                                <el-button type="primary" class="bCbN" v-else>替换图片</el-button>
                            </el-upload>
                        </div>
                    </el-form-item>
                    <el-form-item label="推广链接" prop="landing_page">
                        <el-input v-model="formData.landing_page"></el-input>
                    </el-form-item>
                </el-form>
            </div>
            <div class="formwrap">
                <h3>
                    <i class="el-icon-edit"></i>
                    <span>显示条件</span>
                    <span style="color:#F68D5C;font-size:.6rem;margin-left: 2rem;">不选择条件的情况下都会显示</span>
                </h3>
                <el-form label-width="100px" class="demo-formData">
                    <p v-for="(item, i) in conditionsInfo" :key="i">
                        <el-select v-model="item.key" placeholder="请选择类型" filterable clearable :disabled="item.dis"
                            @change="handleItemKey(item, 'key')">
                            <el-option v-for="dataitem in conditionsData " :key="dataitem.value" :label="dataitem.name"
                                :value="dataitem.value">
                            </el-option>
                        </el-select>
                        <el-select v-model="item.symbol" placeholder="请选择条件" filterable clearable :disabled="item.dis"
                            @change="handleItemKey(item, 'symbol')">
                            <el-option label="是" value="EQUAL">
                            </el-option>
                            <el-option label="不是" value="NOT_EQUAL">
                            </el-option>
                            <el-option label="包含" value="INCLUDE">
                            </el-option>
                        </el-select>
                        <el-select v-model="item.val" placeholder="请选择值（为包含时可多选）" :multiple="item.symbol == 'INCLUDE'"
                            filterable clearable :disabled="item.dis">
                            <template v-if="item.key == '0'">
                                <el-option v-for="dataitem in dictData " :key="dataitem.dictdata_value"
                                    :label="dataitem.dictdata_name" :value="dataitem.dictdata_value">
                                </el-option>
                            </template>
                            <template v-else-if="item.key == '1'">
                                <el-option v-for="dataitem in hasorderData " :key="dataitem.value"
                                    :label="dataitem.name" :value="dataitem.value">
                                </el-option>
                            </template>
                            <template v-else-if="item.key == '2'">
                                <!-- <el-option v-for="dataitem in hasorderData " :key="dataitem.value"
                                    :label="dataitem.name" :value="dataitem.value">
                                </el-option> -->
                                <el-option label="权益会员" value="2"></el-option>
                            </template>
                        </el-select>
                        <span style="margin:0 1rem"></span>
                        <el-button @click="handleConditionsItemFn('save', i, item)" v-show="!item.dis"
                            type="primary">保存</el-button>
                        <el-button @click="handleConditionsItemFn('cancel', i, item)" v-show="!item.dis"
                            type="info">取消</el-button>
                        <el-button @click="handleConditionsItemFn('modification', i, item)" v-show="item.dis"
                            type="warning">修改</el-button>
                        <el-button @click="handleConditionsItemFn('delete', i, item)" type="danger">删除</el-button>
                    </p>
                    <p>
                        <el-button class="bCbN" type="primary" @click="handleConditionsItemFn('push')">
                            <i class="el-icon-plus"></i>
                            添加新条件
                        </el-button>
                    </p>
                </el-form>
            </div>
            <div class="formwrap">
                <h3>
                    <i class="el-icon-edit"></i>
                    <span>其他信息</span>
                </h3>
                <el-form :model="formData" inline label-width="100px" class="demo-formData">
                    <el-form-item label="预算(元)">
                        <el-input type="number" v-model="formData.budget"></el-input>
                    </el-form-item>
                    <el-form-item label="单价(元)">
                        <el-input type="number" v-model="formData.price"></el-input>
                    </el-form-item>
                    <br />
                    <el-form-item label="投放开始时间">
                        <el-date-picker v-model="formData.start_time" format="yyyy-MM-dd HH:mm:ss"
                            value-format="yyyy-MM-dd HH:mm:ss" type="datetime" placeholder="选择日期时间">
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item label="投放结束时间">
                        <el-date-picker v-model="formData.end_time" format="yyyy-MM-dd HH:mm:ss"
                            value-format="yyyy-MM-dd HH:mm:ss" type="datetime" placeholder="选择日期时间">
                        </el-date-picker>
                    </el-form-item>
                </el-form>
            </div>
            <div class="formwrap">
                <h3>
                    <i class="el-icon-edit"></i>
                    <span>广告组</span>
                </h3>
                <el-form :model="formData" :rules="rules" ref="formData" label-width="100px" class="demo-formData">
                    <el-form-item label="广告组" prop="name">
                        <el-select v-model="formData.group" placeholder="请选择广告组" filterable clearable>
                            <el-option v-for=" item in groupData " :key="item.name" :label="item.name"
                                :value="item.name">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-form>
            </div>
            <el-button @click="handlePlanPage = false"> 取 消 </el-button>
            <el-button type="primary" @click="submitFrom"> 确 定 </el-button>
        </div>
    </div>
</template>

<script>
export default {
    components: {},
    data() {
        return {
            formType: 1,
            handlePlanPage: false,
            coverUploading: false,
            uploadHeader: {},
            uploadUrl: '/api/v1/upload?t=ydflowAd',
            paginationInfo: {
                pageSize: 10,
                total: 0,
                pageNo: 1,
            },
            tableData: [],
            dictData: [],
            hasorderData: [
                {
                    name: '未订购',
                    value: '0'
                },
                {
                    name: '已订购',
                    value: '1'
                },
            ],
            conditionsData: [
                {
                    name: '运营商',
                    value: '0'
                },
                {
                    name: '用户',
                    value: '1'
                },
                {
                    name: '权益会员',
                    value: '2'
                },
            ],
            groupData: [
                {
                    name: '推荐广告',
                    value: 0
                }
            ],
            formData: {
                name: '',
                material_url: '',
                landing_page: '',
                group: '',
                conditions: [],
                status: 1,
            },
            conditionsInfo: [],
            rules: {
                name: [
                    { required: true, message: '请输入广告名称', trigger: 'blur' },
                ],
                material_url: [
                    { required: true, message: '请上传广告素材图片', trigger: 'blur' },
                ],
                landing_page: [
                    { required: true, message: '请输入推广链接', trigger: 'blur' },
                ],
                group: [
                    { required: true, message: '请选择广告组', trigger: 'change' },
                ],
            }
        }
    },
    computed: {},
    watch: {},
    created() {
        this.getDict()
        this.getPlan()
    },
    mounted() {

    },
    methods: {
        queryList() {
            this.paginationInfo.pageNo = 1;
            this.getPlan()
        },
        handleOnAndOff(type, item) {
            this.formData = {
                ...item
            }
            this.formData.status = type;
            this.putPlan(item._id)
        },
        handlePlanItem(type, item) {
            switch (type) {
                case 'create':
                    this.formType = 1;
                    this.formData = {
                        name: '',
                        material_url: '',
                        landing_page: '',
                        group: '',
                        conditions: [],
                        status: 1,
                    }
                    this.handlePlanPage = true;
                    break;
                case 'edit':
                    this.formType = 2;
                    this.formData = {
                        ...item
                    }
                    this.conditionsInfo.length = 0;
                    item.conditions.forEach(obj => {
                        obj.dis = true;
                        this.conditionsInfo.push(JSON.parse(JSON.stringify(obj)))
                    })
                    this.formData.end_time = this.$moment(this.formData.end_time).format(`YYYY-MM-DD HH:mm:ss`);
                    this.formData.start_time = this.$moment(this.formData.start_time).format(`YYYY-MM-DD HH:mm:ss`);
                    window.console.log(this.formData)
                    this.handlePlanPage = true;
                    break;
                case 'delete':
                    this.$confirm('此操作将删除该广告, 是否继续?', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then(() => {
                        this.deletePlan(item._id)
                    }).catch(() => {
                        this.$message({
                            type: 'info',
                            message: '已取消删除'
                        });
                    });
                    break;
            }
        },
        handleItemKey(item, type) {
            switch (type) {
                case 'key':
                    item.symbol = '';
                    item.val = '';
                    break;
                case 'symbol':
                    if (item.symbol == 'INCLUDE') {
                        item.val = [];
                    } else {
                        item.val = '';
                    }
                    break;
            }
        },
        handleConditionsItemFn(type, i, item) {
            switch (type) {
                case 'push':
                    this.conditionsInfo.push({
                        key: '', symbol: '', val: '', dis: false
                    })
                    break;
                case 'delete':
                    this.conditionsInfo.splice(i, 1)
                    break;
                case 'cancel':
                    item.key = ''
                    item.symbol = ''
                    item.val = ''
                    item.dis = false
                    break;
                case 'save':
                    if (item.key && item.symbol && item.val) {
                        item.dis = true
                    } else {
                        this.$message({
                            message: '有空选项不能保存！',
                            type: 'warning'
                        });
                    }
                    break;
                case 'modification':
                    item.dis = false
                    break;
            }
        },
        coverUploadSuccess(res) {
            if (res.code === 0 && res.data.path) {
                this.formData.material_url = this.$STAITC_HOST + res.data.path;
            } else {
                this.$message.error('上传失败')
            }
            this.coverUploading = false
        },
        coverUploadError() {
            this.coverUploading = false
            this.$message.error('上传失败')
        },
        coverBeforeUpload() {
            this.coverUploading = true
        },
        handleSizeChange(val) {
            window.console.log(`每页 ${val} 条`);
            this.getPlan();
        },
        handleCurrentChange(val) {
            window.console.log(`当前页: ${val}`);
            this.getPlan();
        },
        getDict() {
            this.$service.CommonService
                .getDict({ dict: 'carrier_id' })
                .then(res => {
                    if (res.data.code === 0) {
                        this.dictData = res.data.data.dict_data;
                    }
                })
        },
        getPlan() {
            this.$service.AdmanagerService
                .getPlan(this.paginationInfo)
                .then(res => {
                    if (res.data.code === 0) {
                        let dataInfo = res.data.data
                        this.paginationInfo.total = dataInfo.total;
                        this.tableData = dataInfo.adPlans;
                    }
                })
        },
        postPlan() {
            this.$service.AdmanagerService
                .postPlan(this.formData)
                .then(res => {
                    if (res.data.code === 0) {
                        this.$message.success('新增成功！！！');
                        this.handlePlanPage = false;
                        this.queryList()
                    }
                })
        },
        putPlan(id) {
            this.$service.AdmanagerService
                .putPlan(id, this.formData)
                .then(res => {
                    if (res.data.code === 0) {
                        this.$message.success('修改成功！！！');
                        this.handlePlanPage = false;
                        this.getPlan()
                    }
                })
        },
        submitFrom() {
            if (!this.formData.name) {
                this.$message.error('广告名称未填写');
                return
            }
            if (!this.formData.material_url) {
                this.$message.error('素材图片未上传');
                return
            }
            if (!this.formData.landing_page) {
                this.$message.error('推广链接未填写');
                return
            }
            window.console.log(this.formData.group)
            if (this.formData.group == '') {
                this.$message.error('广告组未选择');
                return
            }
            if (this.conditionsInfo.length > 0) {
                this.formData.conditions.length = 0;
                this.conditionsInfo.forEach(item => {
                    if (item.dis) {
                        this.formData.conditions.push(item)
                    }
                })
            }
            switch (this.formType) {
                case 1:
                    this.postPlan()
                    break;
                case 2:
                    this.putPlan(this.formData._id)
                    break;
            }
        },
        deletePlan(id) {
            this.$service.AdmanagerService
                .deletePlan(id)
                .then(res => {
                    if (res.data.code === 0) {
                        this.$message.success('删除成功！！！');
                        this.getPlan()
                    }
                })
        },
    },
    beforeCreate() { }, // 生命周期 - 创建之前
    beforeMount() { }, // 生命周期 - 挂载之前
    beforeUpdate() { }, // 生命周期 - 更新之前
    updated() { }, // 生命周期 - 更新之后
    beforeDestroy() { }, // 生命周期 - 销毁之前
    destroyed() { }, // 生命周期 - 销毁完成
    activated() { } // 如果页面有keep-alive缓存功能,这个函数会触发
}
</script>
<style lang='less' scoped>
.advertisingManagement {
    .bCbN {
        background-color: #F68D5C;
        border: none;
    }

    .formWrite {
        .formwrap {
            width: 60%;
            margin: 1rem 0;
            border: 1px solid #999;
            padding: 0 1rem;
            border-radius: .5rem;
            background-color: white;

            h3 {
                color: #3786DD;
            }
        }
    }
}
</style>